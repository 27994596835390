<template>
  <div class="_wrap" v-loading="loading">
    <div class="_banner">
      <img
        :src="
          $IMG_URL + $store.state.webData.top_image.information_detail_image
        "
        alt="八路军驻洛办事处纪念馆-官方网站"
      />
    </div>

    <div class="_content">
      <div class="_auto">
        <div class="_bread">
          <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/information' }"
              >资讯</el-breadcrumb-item
            >
            <el-breadcrumb-item>{{ breadName }}</el-breadcrumb-item>
          </el-breadcrumb>
        </div>

        <div class="_nr">
          <h4 class="_h4">{{ detail.title }}</h4>

          <p class="_p">
            <span class="_impact"> 时间：{{ detail.release_date }} </span>
            <span class="_impact"> 浏览量：{{ detail.hits }} </span>
          </p>

          <div
            class="_html"
            v-html="detail.content"
            ref="myHtml"
            v-if="detail.pdf == '' || detail.pdf == null"
          ></div>

          <div class="_html" v-else>
            <iframe
              :src="'/pdf/web/viewer.html?file=' + $IMG_URL + detail.pdf"
              frameborder="0"
              id="myIframe"
            ></iframe>
          </div>
        </div>
      </div>
    </div>

    <mainFooter ref="mainFooterRef"></mainFooter>
  </div>
</template>

<script>
import { informationDetail } from "@/api/getData";
export default {
  data() {
    return {
      loading: false,
      id: "",
      breadName: "通告公告",
      detail: {},
      title: "",
    };
  },
  // 动态设置页面标题、关键字和描述等属性
  metaInfo() {
    return {
      title: this.title,
      meta: [
        // { name: "keywords", content: this.keywords },
        // { name: "description", content: this.desc },
      ],
    };
  },
  created() {},
  async mounted() {
    await this.getData();
    this.$scallHtmlImg();

    setTimeout(() => {
      var table = document.querySelectorAll("._html table");
      console.log(table);
      if (table.length > 0) {
        table.forEach((item) => {
          item.border = "1";
          item.style.borderCollapse = "collapse";
          item.style.width='80%'
          item.style.marginLeft='10%'
        });
      }
    }, 500);
  },
  methods: {
    async getData() {
      this.id = this.$route.query.id;
      this.breadName = this.$route.query.breadName;
      console.log(this.$route);
      const params = {
        id: this.id,
      };
      await informationDetail(params).then((res) => {
        console.log(res);
        this.detail = res.data;
        this.title = res.data.title;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../common/mixin.scss";

._wrap {
  width: 100%;
  overflow: hidden;
  background-color: #fdfaf6;
}

#myIframe {
  width: 100%;
  height: 900px;
}

._banner {
  width: 100%;
  height: 700px;
  // height: 560px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

._content {
  width: 100%;
  margin-bottom: 100px;

  ._auto {
    width: 75%;
    margin: 0 auto;

    ._bread {
      margin-top: 24px;
    }

    ._nr {
      margin-top: 66px;

      ._h4 {
        font-size: 30px;
        text-align: center;
      }

      ._p {
        text-align: center;
        margin-top: 20px;

        span {
          font-size: 20px;
          color: #999;
          margin: 0 30px;
          position: relative;

          &:nth-child(1)::after {
            content: "";
            display: inline-block;
            width: 1px;
            height: 16px;
            background-color: #dfdfdf;
            position: absolute;
            top: 5px;
            right: -30px;
          }
        }
      }

      ._html {
        margin-top: 60px;

        ._cont {
        }

        img {
          width: 100%;
        }
      }
    }
  }
}

::v-deep .el-breadcrumb__inner {
  color: #969696 !important;
  font-weight: normal !important;
}

@include respondTo("phone") {
  ._banner {
    height: 300px;
  }

  ._content {
    margin-bottom: 50px;
    ._auto {
      padding: 0 8px;
      ._bread {
        margin-top: 20px;
      }
      ._nr {
        margin-top: 20px;
        ._h4 {
          font-size: 22px;
        }
        ._p {
          span {
            font-size: 16px;
            margin: 0 20px;
          }
        }
      }
    }
  }
}
</style>